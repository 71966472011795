<template>
  <div class="w-100 mh-80">
    <b-card
      id="legalCard"
      class="p-lg-5 p-md-3 p-2 w-100"
    >
      <b-spinner
        v-if="isLoadingPolicy"
        variant="primary"
      />

      <div v-if="policy != null">
        <h1>
          {{ policy.policyTitle }}
        </h1>
        <p>
          {{ policy.policySubTitle }}
        </p>
        <div v-html="policyHtml" />
      </div>

      <template #footer>
<!--        <b-button-->
<!--          variant="outline-secondary"-->
<!--          :disabled="isAcceptingPolicy"-->
<!--        >-->
<!--          {{ $t('legalPolicies.doNotAccept') }}-->
<!--        </b-button>-->
        <b-button
          variant="primary"
          :disabled="isAcceptingPolicy"
          @click="acceptPolicy"
        >
          {{ isAcceptingPolicy ? $t('legalPolicies.accepting') : $t('legalPolicies.accept') }}
        </b-button>
      </template>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BSpinner,
} from 'bootstrap-vue'

export default {
  name: 'AcceptPolicy',
  components: {
    BButton,
    BCard,
    BSpinner,
  },
  data() {
    return {
      policySlug: this.$route.params.policySlug,
      isLoadingPolicy: true,
      policy: null,
      isAcceptingPolicy: false,
      error: null,
    }
  },
  computed: {
    policyHtml() {
      return atob(this.policy.content)
    },
  },
  mounted() {
    this.fetchPolicy()
  },
  methods: {
    fetchPolicy() {
      this.$http.get(`/user/policies/${this.policySlug}?embedHtml=true`)
        .then(response => {
          this.policy = response.data
        })
        .catch(error => {
          // FIXME: Add better handling than this foolishness in future
          console.log(error)
        })
        .finally(() => {
          this.isLoadingPolicy = false
        })
    },
    acceptPolicy() {
      this.isAcceptingPolicy = true

      this.$http.post('/user/policies/accept-policy', {
        policyName: this.policy.policySlug,
        policyVersion: this.policy.policyVersion,
      })
        .then(async () => {
          // we all hate this but we have to do it until we implement SSE
          await new Promise(r => setTimeout(r, 5000))

          this.$router.replace({
            name: 'home',
            params: {
              forceActivityRefresh: true,
            },
          })
        })
        .catch(() => {
          this.error = this.$t('generic.apiError')
        })
        .finally(() => {
          this.isAcceptingPolicy = false
        })
    },
  },
}
</script>

<style scoped>
@media (min-width: 992px) {
  .mh-80 {
    min-height: 80vh;
  }
}
</style>

<style>
#legalCard .card-footer {
  border: none !important;
  text-align: right !important;
}
</style>
